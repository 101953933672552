<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card> 
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="8"
                    lg="11"
                    md="10"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                    >
                    </s-scanner-qr>
                </v-col>
            </v-row>

            <v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:filter="filter"
						:config="config"
						searchInput
						title="Listado de Pallets Despachados"
						no-full
						height="auto"
						dense
						ref="gridPalletDispatch"
					>
                    <!-- @rowSelected="rowSelected($event)" -->
						<!-- Filtros -->
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
								</v-row>
								
								<v-row class="s-col-form">
									
								</v-row>
							</v-container>
						</template>

						<!-- <template v-slot:details="{ row }">
							<v-btn
								x-small
								fab
								style="margin: 6px 6px 6px 6px; background-color: #dd3b62; color: white;"
								@click="deleteParihuela(row)">
								<v-icon
                                    style="font-size: 16px !important">
                                    fas fa-times
                                </v-icon>
							</v-btn>
						</template> -->
                        
						
					</s-crud>
				</v-col>
			</v-row>
        </v-card>
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';
    import _sFrzChamberPalletDispatchService from "@/services/FrozenProduction/FrzChamberPalletDispatch.js";

    export default{
        components: { sScannerQr},
        data:() =>({
            
            clearInput:false,
            configScann: {}, 
            filter: {
                DateBegin: null,
                DateEnd: null,                    
            },
            config: {
				model: {
					CpdID: "ID",
                    DateBegin: "date",
                    DateEnd: "date",
                    SecStatus: "SecStatus",
                    details:"",
				},
				service: _sFrzChamberPalletDispatchService,
				headers: [								
					{ text: "ID", value: "CpdID", width: 20 },
                    { text: "N° Pallet", value: "PalletGroupID", width: 20 },
					{ text: "Fecha", value: "DateIncome", width: 60 },
					{ text: "Hora", value: "HourIncome", width: 30 },
					{ text: "Cliente", value: "CumNombre", width: 60 },
                    { text: "Cultivo", value: "TypeCultiveName", width: 60 },
                    { text: "variedad", value: "VrtDescription", width: 60 },
              
					{ text: "Numero de Cajas", value: "NumberBoxes", width: 60 },
					{ text: "Peso Neto", value: "WeightNetBoxes", width: 60 },					
                    // { text: "Detalle", value: "details", width: 20 },		
				],
			},

            
            
            
        }),
        methods:{
            onValue(val)
            {
                // console.log('val',val);
                if (isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {

                //     console.log('qr leido',val)
                    let item = {};
                    item.PalletGroupID = val;
                    console.log("item", item);
                    _sFrzChamberPalletDispatchService.save(item, this.$fun.getUserID())
                    .then( r => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$refs.gridPalletDispatch.refresh();
                        }
                    });
                }

                
            },
            saveLosse(){
                

            },
            listPallet(){
                
            }

        },
        created(){
            // this.listPallet();
        }
    }
</script>